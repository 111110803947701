import React, { useState } from "react";
import styles from "./Gallery.module.css";
import { useSelector } from "react-redux";
import { Image } from "antd";

export default function Gallery() {
  const select = useSelector((state) => state.gallery);
  const [isActive, setIsActive] = useState("2016");
  const [currentYear, setCurrentYear] = useState(select.img2016);

  function toggle(e) {
    setIsActive(e.target.innerHTML);
    if (e.target.innerHTML === "2016") {
      setCurrentYear(select.img2016);
    } else if (e.target.innerHTML === "2017") {
      setCurrentYear(select.img2017);
    } else if (e.target.innerHTML === "2018") {
      setCurrentYear(select.img2018);
    } else if (e.target.innerHTML === "2019") {
      setCurrentYear(select.img2019);
    } else if (e.target.innerHTML === "2020") {
      setCurrentYear(select.img2020);
    } else if (e.target.innerHTML === "2021") {
      setCurrentYear(select.img2021);
    } else if (e.target.innerHTML === "2022") {
      setCurrentYear(select.img2022);
    } else if (e.target.innerHTML === "2023") {
      setCurrentYear(select.img2023);
    } else if (e.target.innerHTML === "2024") {
      setCurrentYear(select.img2024);
    }
  }

  function render(i) {
    return (
      <div className={styles.gallery_wrapper_fotos}>
        <h2>{i}</h2>
        <p>{`Фото на странице ${currentYear.length}`}</p>
        <div className={styles.gallery_fotos}>
          {currentYear.map((item) => (
            <div key={item.id} className={styles.gallery_item}>
              <Image
                key={item.id}
                src={item}
                alt=""
                // width={200}
                height={150}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  let res;

  switch (isActive) {
    case "2016":
      res = render("2016");
      break;

    case "2017":
      res = render("2017");
      break;

    case "2018":
      res = render("2018");
      break;

    case "2019":
      res = render("2019");
      break;

    case "2020":
      res = render("2020");
      break;

    case "2021":
      res = render("2021");
      break;

    case "2022":
      res = render("2022");
      break;

    case "2023":
      res = render("2023");
      break;

    case "2024":
      res = render("2024");
      break;

    default:
      res = render("2016");
  }

  return (
    <div className={styles.gallery_wrapper}>
      <h1>Галерея</h1>
      <div className={styles.selector}>
        <Button text={"2016"} toggle={toggle} isActive={isActive} />
        <Button text={"2017"} toggle={toggle} isActive={isActive} />
        <Button text={"2018"} toggle={toggle} isActive={isActive} />
        <Button text={"2019"} toggle={toggle} isActive={isActive} />
        <Button text={"2020"} toggle={toggle} isActive={isActive} />
        <Button text={"2021"} toggle={toggle} isActive={isActive} />
        <Button text={"2022"} toggle={toggle} isActive={isActive} />
        <Button text={"2023"} toggle={toggle} isActive={isActive} />
        <Button text={"2024"} toggle={toggle} isActive={isActive} />
      </div>

      {res}
    </div>
  );
}

function Button({ text, isActive, toggle }) {
  return (
    <div>
      {isActive === text ? (
        <button onClick={(e) => toggle(e)} className={`${styles.activeBtn} `}>
          {text}
        </button>
      ) : (
        <button
          onClick={(e) => toggle(e)}
          className={`${styles.selector_btn} `}
        >
          {text}
        </button>
      )}
    </div>
  );
}
